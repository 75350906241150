import React, { Fragment } from 'react';

import { isEstimate, isOneToManyPaymentRequest, isPaymentRequest } from 'shared/utils';
import { TXN_MAP } from 'types/constants';

export interface Props {
  cdn: string;
  discovery: {
    sale: {
      type: string;
      subType: string | undefined | null;
      requestedAmount: number | null | undefined;
    };
    companyInfo: {
      companyName: string;
      sourceOffering: string;
    };
  };
}

const SocialMeta: React.FC<Props> = ({ cdn, discovery }) => {
  if (!cdn || !discovery) {
    //log
    return null;
  }

  try {
    // Invoice default values
    const {
      sale: { type, subType, requestedAmount },
      companyInfo: { companyName, sourceOffering },
    } = discovery;
    let title = `Invoice from ${companyName}`;
    let description = `Review and pay an invoice online.`;
    let image = `${cdn}/meta/meta_invoice.png`;
    let showDescription = true;

    if (isPaymentRequest(type)) {
      title = `Payment request from ${companyName}`;
      description = '';
      image = `${cdn}/meta/meta_pr.png`;
      showDescription = false;
      if (isOneToManyPaymentRequest(type, subType)) {
        image = `${cdn}/meta/meta_pr_1m.png`;
      }
      switch (sourceOffering) {
        case TXN_MAP.OFFERING_TYPES.QBDT:
          image = `${cdn}/meta/meta_pr_qbdt.png`;
          break;
        case TXN_MAP.OFFERING_TYPES.QBMONEY:
          image = `${cdn}/meta/meta_pr_money.png`;
          description = 'Pay now without signing up or any fees.';
          showDescription = true;
          break;
      }
    } else if (isEstimate(type)) {
      title = `Review ${companyName} estimate`;
      image = `${cdn}/meta/meta_estimate.png`;
      description = 'Review, accept or decline the estimate online';
      //deposit
      if (typeof requestedAmount === 'number') {
        description = 'Review the estimate. Accept and pay the deposit online to seal the deal.';
      }
    }

    return (
      <Fragment>
        <meta property="og:title" content={title} />
        {showDescription && <meta property="og:description" content={description} />}
        <meta property="og:image" content={image} />
      </Fragment>
    );
  } catch (e) {
    return null;
  }
};
export default SocialMeta;
