class BaseReporter {
  static _convertObjectToSplunkString(originalReport) {
    const _innerFunc = (report) => {
      if (typeof report !== 'object' || report === null) {
        return report;
      }

      try {
        JSON.stringify(report);
      } catch (e) {
        throw new Error('Circular Dependency in report!');
      }

      if (Object.keys(report).length === 0) {
        return '';
      }

      return Object.keys(report)
        .map((key) => {
          return `${key}="${_innerFunc(report[key])}"`;
        })
        .join(', ');
    };

    // Guard that the timestamp is available and it's the first string.
    if (
      typeof originalReport !== 'object' ||
      originalReport === null ||
      Object.keys(originalReport).length === 0
    ) {
      throw Error(
        '_convertObjectToSplunkString can not be called directly. It should come from the Logger.'
      );
    }

    const { timestamp } = originalReport;
    if (!timestamp || typeof timestamp !== 'string') {
      throw Error('Logger should normalize the raw message with a key=timestamp and value=string');
    }
    delete originalReport.timestamp;

    return `timestamp=${timestamp}, ${_innerFunc(originalReport)}`;
  }

  constructor({ reportOrigin, cpVersion }) {
    this.reportOrigin = reportOrigin;
    this.cpVersion = cpVersion;
  }

  enrich(raw, opts, convertToSplunk = true) {
    let isNewSyntax = false;
    if (typeof raw === 'string') {
      raw = {
        message: raw,
      };
    } else {
      // only the new syntax contains this kind of object
      isNewSyntax = raw.newSyntax === true;
    }

    let enriched = {
      ...raw,
    };
    if (!isNewSyntax) {
      // make sure timestamp is the first prop
      const { timestamp } = enriched;
      if (timestamp || typeof timestamp === 'string') {
        delete enriched.timestamp;
        enriched = { timestamp, ...enriched };
      }

      if (!enriched.hasOwnProperty('CPVersion')) {
        enriched['CPVersion'] = this.cpVersion;
      }

      if (!enriched.hasOwnProperty('EventSender')) {
        enriched['EventSender'] = 'ssr_server';
        enriched['EventOrigin'] = this.reportOrigin;
      }

      if (!enriched.hasOwnProperty('LogType') && opts && opts.logType) {
        enriched['LogType'] = opts.logType;
      }

      const propsToEnrich = ['ssrtid', 'token', 'transactionType', 'merchantId', 'locale'];
      propsToEnrich.forEach((prop) => {
        if (!enriched.hasOwnProperty(prop) && opts && opts[prop]) {
          enriched[prop] = opts[prop];
        }
      });
    }
    return convertToSplunk ? BaseReporter._convertObjectToSplunkString(enriched) : enriched;
  }
}

module.exports = BaseReporter;
