class Batcher {
  constructor({ batchWindowLengthMillisecond = 350, invokedCallback }) {
    if (typeof invokedCallback !== 'function')
      throw new Error(`invokedCallback must be passed to Batcher constructor`);
    this.batchWindowLengthMillisecond = batchWindowLengthMillisecond;
    this.invokedCallback = invokedCallback;
    this._setBatchDefaultValues();

    // closeBatch is being executed from setTimeout with a different scope
    this._closeBatch = this._closeBatch.bind(this);
  }

  push(item) {
    if (!this.isActiveBatch) {
      this._activateBatch();
    }
    this.buffer.push(item);
  }

  _activateBatch() {
    this.isActiveBatch = true;
    setTimeout(this._closeBatch, this.batchWindowLengthMillisecond);
  }

  _closeBatch() {
    this.invokedCallback(this.buffer);
    this._setBatchDefaultValues();
  }

  _setBatchDefaultValues() {
    this.buffer = [];
    this.isActiveBatch = false;
  }
}

module.exports = Batcher;
