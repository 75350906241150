/* istanbul ignore file */

// The file is configuration for a 3rd party lib, nothing to test

import * as Sentry from '@sentry/nextjs';

import type { RootState } from 'store/store';

export const initSentry = (initialConfig: RootState) => {
  if (!initialConfig) {
    return;
  }
  if (!initialConfig.config) {
    return;
  }
  Sentry.configureScope(function (scope) {
    scope.addEventProcessor(function (event) {
      return new Promise(function (resolve) {
        event.environment = initialConfig.config.env;
        return resolve(event);
      });
    });
  });
  Sentry.setContext('config', {
    ssrtid: initialConfig.config.ssrtid,
    originatingIp: initialConfig.config.originatingIp,
  });
  if (initialConfig.sale) {
    Sentry.setContext('sale', {
      amount: initialConfig.sale.amount,
      type: initialConfig.sale.type,
      txnDate: initialConfig.sale.txnDate,
      currency: initialConfig.sale.currencyInfo?.currency,
      id: initialConfig.sale.id,
      referenceNumber: initialConfig.sale.referenceNumber,
      recipientEmail: initialConfig.auth.recipientEmail,
    });
  }
  if (initialConfig.companyInfo) {
    Sentry.setContext('companyInfo', {
      companyName: initialConfig.companyInfo.companyName,
      language: initialConfig.companyInfo.language,
      region: initialConfig.companyInfo.region,
      primaryEmail: initialConfig.companyInfo.contactMethods?.[0]?.primaryEmail?.emailAddress,
    });
  }
  if (initialConfig.payment) {
    Sentry.setContext('payment', {
      balanceAmount: initialConfig.payment.balanceAmount,
      inputAmount: initialConfig.payment.inputAmount,
      amount: initialConfig.payment.amount,
      isAmountValid: initialConfig.payment.isAmountValid,
      payPalProcessor: initialConfig.payment.payPalProcessor,
      isPayPalCommerceInvoice: initialConfig.payment.isPayPalCommerceInvoice,
      isSavePaymentMethodChecked: initialConfig.payment.isSavePaymentMethodChecked,
    });
  }
  if (initialConfig.auth) {
    Sentry.setUser({
      username: initialConfig.auth.username,
      realmId: initialConfig.auth.realmId,
      entityId: initialConfig.auth.entityId,
    });
  }
};
