import debugCreator from 'debug';
import BaseReporter from './reporter.base';
import HttpClient from 'server/helpers/HttpClient';
import { authActions } from 'store/auth/slice';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
// import Batcher from 'shared/Batcher';
const Batcher = require('shared/Batcher');

const debug = debugCreator('reporting:ClientSplunkHandler');
const httpClient = HttpClient.getInstance();

// SplunkClientReporter private function:
let reporterService;

class SplunkClientReporter extends BaseReporter {
  /**
   * @param {import('redux').Store} store
   */
  constructor(store) {
    const {
      config: { cpVersion, portal, ssrtid, dockerTag },
      auth,
      insight: { offeringId, token, domainId, merchantId },
      sale: { type, subType, subscriptionPaymentsSetting },
      companyInfo = {},
    } = store.getState();

    super({ reportOrigin: 'client', cpVersion });

    const { entityId, realmId, ticket, isUserSignedIn, syncToken, authToken } = auth;

    this.NAME = 'SplunkClient';
    this.store = store;
    this.cpVersion = cpVersion;
    this.portal = portal;
    this.endpoint = `/${portal}/rest/reporting`;
    this.imageName = dockerTag;
    this.transactionType = `${type}`;
    this.offeringId = offeringId || '';
    this.merchantId = merchantId;
    this.locale = companyInfoSelectors.localeSelector(companyInfo);

    if (subType) {
      this.subType = subType;
    }
    if (subscriptionPaymentsSetting) {
      this.subscriptionPaymentsSetting = subscriptionPaymentsSetting;
    }

    this.token = token;
    this.ssrtid = ssrtid;
    this.headers = {
      'Content-Type': 'application/json',
      'Intuit-DomainId': domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-RealmId': realmId,
      'Intuit-ACSToken': token,
      sessionTicket: ticket,
      'user-signed-in': typeof isUserSignedIn === 'boolean' ? isUserSignedIn.toString() : 'false',
      syncToken: syncToken,
      'ssr-session-id': ssrtid,
      Authorization: `Bearer ${authToken}`,
    };

    this.endpoint = `/${portal}/rest/reporting/batch`;
    this.logBatcher = new Batcher({
      invokedCallback: (batchItems) => {
        httpClient({
          url: this.endpoint,
          method: 'POST',
          headers: this.headers,
          endpoint: this.endpoint,
          ssrtid: this.ssrtid,
          token: this.token,
          ignoreErrorInterceptor: true,
          ignoreProfilingInterceptor: true,
          event: 'splunkReporting',
          data: {
            messages: batchItems,
          },
        }).then((response) => {
          const {
            data: { auth },
          } = response;
          if (auth) {
            this.store.dispatch(authActions.refreshAuthenticationSuccessful({ auth }));
          }
        });
      },
    });
    reporterService = (message) => this.logBatcher.push(message);

    debug(`Name: ${this.NAME}`);
    debug(`Endpoint: ${this.endpoint}`);
    debug(`token: ${this.token}`);
    debug(`ssrtid: ${this.ssrtid}`);

    this.report = this.report.bind(this);
  }

  enrich(raw, opts) {
    let enrichedOpts = Object.assign({}, opts, {
      ssrtid: this.ssrtid,
      token: this.token,
      transactionType: this.transactionType,
      merchantId: this.merchantId,
      locale: this.locale,
    });
    return super.enrich(raw, enrichedOpts, false);
  }

  report(message, opts) {
    // eslint-disable-next-line no-undef
    if (localStorage && localStorage.getItem('log') === '1') {
      const enrichedMessage = this.enrich(message, opts);
      // eslint-disable-next-line no-console
      console.info(enrichedMessage);
    }

    debug(`ssrtid=${this.ssrtid}, token=${this.token}, transactionType=${this.transactionType}`);

    reporterService({
      data: this.enrich(message, opts),
      opts: {
        userLogLevel: opts.logLevel,
        ...opts,
      },
    });
  }
}

export default SplunkClientReporter;
